$link-hover-decoration: none;    // remove underline from button links

$link-color: currentColor;            // set link color based on the used material theme
$link-hover-color: currentColor;

/*$theme-colors: (
  primary: #2979ff
);*/


//original container

/*$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);*/

/*$container-max-widths: (
  sm: 599px,
  md: 810px,
  lg: 1040px,
  xl: 1190px
);*/