/* You can add global styles to this file, and also import other style files */

/*html, body { height: 100%; }
body { margin: 0; font-family: "Work Sans",Arial, Helvetica, sans-serif; }*/

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/* You can add global styles to this file, and also import other style files */




.mat-drawer-container {
    background-color: #fafafa;
    color: rgba(0,0,0,.87);
}


@import "styles-reset";



.cursor-pointer
{
    cursor: pointer;
}

.margin-top {
  margin-top: 2em !important;
}

.margin-bottom-1 {
  margin-bottom: 1em !important;
}

.margin-bottom {
  margin-bottom: 2em !important;
}

.text-center
{
    text-align: center;
}

.text-justify
{
    text-align: justify;
}

.text-right
{
    text-align: right;
}

.white-color
{
    color: #fff !important;
}

.white-bg-color
{
    background-color: #fff !important;
}

.black-color
{
    color: #000;
}

.mat-button, .mat-flat-button, .mat-icon-button, .mat-stroked-button, .mat-raised-button {

  border-radius: 0px !important; 

}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}

.full-width
{
  width: 100%;
}

.done {
  position: fixed;
  bottom: 60px;
  right: 60px;
  color: white;
}





@media(max-width:767px){


  .mobile-margin-top {

    margin-top: 18px !important;

  }

  .mobile-margin-top-buttons {

    margin-top: 10px !important;

  }

  #appointment2 {
      

      .container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
          padding-right: 0px;
          padding-left: 0px;
      }

      .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
          padding-right: 0px;
          padding-left: 0px;
      }

    

  }


  #appointment-section {
      

     

      .section {
        padding-bottom:0px;
      }
    

  }


}

.mat-dialog-actions {
    justify-content: space-between !important;
}

/* section */

.line-section {
    border-bottom: 1px solid #dddcdc;
}

.section {
    padding: 80px 0;
    padding-top: 80px;
    padding-right: 0px;
    padding-bottom: 80px;
    padding-left: 0px;
}
