* {
  &:active,
  :focus {
    outline: none !important;
  }
}

.no-border {
  border-radius: 0px !important;
}


.mat-checkbox-layout {
    cursor: inherit;
    white-space: normal !important;
}

.mat-step-label {
    white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

.country-selector{
  opacity:1 !important;
}

.mat-expansion-panel-content {
    background: #fbf7f6;
}

.mat-expansion-panel-body {
    margin-top:1em;
}